import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

import api from "../common/api";
import { Notice } from "../common/types";

import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import SvgIcon, { SvgIconType } from "../svg";
import TopBanner, { TopBannerLocation } from "../components/TopBanner";

import { useAppLinkContext } from "../modules/useAppLink";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";

export function ServiceNoteHeader() {
  const navigate = useNavigate();
  return (
    <HFlex a-c height={60}>
      <SvgIcon size={24} icon={SvgIconType.Back} onClick={() => navigate(-1)} />
    </HFlex>
  );
}

function ServiceNoteSection({ content }: any) {
  return (
    <VFlex g-12 bd-t2 bc-t1 bdr-16>
      <Text p-16 t-16-g5 l-n>
        {content}
      </Text>
    </VFlex>
  );
}

function MannerNoteSection({ title, content, note, color }: any) {
  return (
    <HFlex bd-t2 bdr-16>
      <Text p-16 width={100} bc-t1 t-18-600-g4 sized>
        {title}
      </Text>
      <VFlex g-8 p-16>
        {note ? (
          <Text t-16-s7 l-n>
            {note}
          </Text>
        ) : (
          <Text t-18-600-s7 style={{ color }} l-n>
            {content}
          </Text>
        )}
      </VFlex>
    </HFlex>
  );
}

function ConditionNoteSection({ title, content, note, color }: any) {
  return (
    <HFlex>
      <Text p-16 width={100} bc-t1 t-18-600-g4 sized>
        {title}
      </Text>
      <VFlex g-8 p-16>
        <Text t-18-600-s7 style={{ color }} l-n>
          {content === "관심필요" ? "관찰이 필요해요" : content}
        </Text>
        {note && (
          <Text t-16-s7 l-n>
            {note}
          </Text>
        )}
      </VFlex>
    </HFlex>
  );
}

export default function ServiceNote() {
  const { initProperties, openAppLink } = useAppLinkContext();
  const { trackEvent, setIdentifyObj } = useAmplitudeTrack();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");
  const [data, setData] = useState<Notice>();
  const [slideIndex, setSlideIndex] = useState(0);

  const [btnWidth, setBtnWidth] = useState(182);
  const [btnDisplay, setBtnDisplay] = useState("block");

  useEffect(() => {
    (async () => {
      const data = await api.get(`/ext/reservation/notice?key=${key}`);
      setData(data);

      const { group, pet } = await api.get(`/ext?key=${key}`);
      initProperties({ groupId: group.id, petId: pet.id });
      setIdentifyObj("petId", pet.id);
      setIdentifyObj("groupId", group.id);
      trackEvent(amplitudeEvents.serviceNoteLand, { reservationNumber: key });
    })();
  }, [key]);

  useEffect(() => {
    const handleShowButton = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (window.scrollY > 10) {
        setBtnWidth(56);
        setBtnDisplay("none");

        if (scrollTop + clientHeight >= scrollHeight) {
          setBtnWidth(182);
          setBtnDisplay("block");
        }
      } else {
        setBtnWidth(182);
        setBtnDisplay("block");
      }
    };

    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("링크가 복사되었습니다.");
    } catch (e) {
      toast.error("복사에 실패하였습니다. 다시 시도해주세요.");
    }
  };

  // TODO
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <TopBanner location={TopBannerLocation.serviceNote} />

      <VFlex
        ref={divRef}
        p-24
        style={{
          maxWidth: 480,
          width: "100%",
          minHeight: "100vh",
          paddingBottom: 112,
          // background:
          //   "#70C391 url(/image/wave_bg.png) repeat-y center / 100% auto",
          background: "#70C391",
        }}
      >
        {state && <ServiceNoteHeader />}
        <VFlex p-16-b a-c>
          <HFlex a-c j-b height={64} style={{ width: "100%" }}>
            <SvgIcon width={146} height={32} icon={SvgIconType.LogoW} />
            <HFlex
              bc-ww
              bdr-24
              p-8-tb
              p-16-rl
              onClick={() => navigate("/coparent")}
            >
              <Text t-14-500-s6>가족과 함께 관리</Text>
            </HFlex>
          </HFlex>
        </VFlex>
        {data && (
          <VFlex bc-ww bdr-24 p-24-rl p-40-tb>
            <VFlex g-4>
              <HFlex a-c j-b>
                <Text t-24-600-s6 l-n>{`${data.pet.name}의 알림장`}</Text>
                {!data.attachments && (
                  <HFlex g-24>
                    <SvgIcon
                      width={24}
                      height={24}
                      icon={SvgIconType.Share}
                      onClick={() => {
                        handleCopyClipBoard(window.location.href);
                        trackEvent(amplitudeEvents.serviceNoteShare, {
                          reservationNumber: key,
                        });
                      }}
                    />
                  </HFlex>
                )}
              </HFlex>

              <HFlex a-c j-b>
                <Text t-16-s4>
                  {format(new Date(data.created * 1000), "yyyy.MM.dd")}
                </Text>
              </HFlex>
            </VFlex>
            {data.attachments && data.attachments.length > 0 && (
              <VFlex g-8>
                <HFlex rel m-12-t>
                  <Swiper
                    style={{
                      position: "relative",
                      width: "100%",
                      borderRadius: "16px",
                    }}
                    slidesPerView={1}
                    threshold={10}
                    onActiveIndexChange={(swiper) =>
                      setSlideIndex(swiper.activeIndex)
                    }
                  >
                    {data.attachments.map((attachment: any, i: number) => (
                      <SwiperSlide key={i}>
                        <Image
                          width={"100%"}
                          src={attachment.path}
                          onClick={() => {
                            navigate("/servicenotephotos", {
                              state: { data, key },
                            });
                          }}
                          style={{ objectFit: "cover", aspectRatio: "1/1" }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </HFlex>

                <HFlex j-b a-c>
                  <HFlex a-c g-8>
                    <SvgIcon
                      width={24}
                      height={24}
                      icon={SvgIconType.Share}
                      onClick={() => {
                        handleCopyClipBoard(window.location.href);
                      }}
                    />
                  </HFlex>

                  {data.attachments.length <= 1 ? null : (
                    <HFlex g-8>
                      {data.attachments.map((_: any, i: number) => (
                        <div
                          key={i}
                          style={{
                            display: "inline-block",
                            width: 8,
                            height: 8,
                            background:
                              slideIndex === i ? "#27BD63" : "#E4E8E6",
                            borderRadius: 8,
                          }}
                        ></div>
                      ))}
                    </HFlex>
                  )}
                </HFlex>
                {data.attachments && data.attachments.length > 0 && (
                  <HFlex
                    c-c
                    bc-t2
                    m-16-t
                    bdr-12
                    p-12-tb
                    p-12-l
                    p-8-r
                    g-8
                    onClick={() => {
                      trackEvent(
                        amplitudeEvents.serviceNoteImgDownloadGuideClick
                      );
                      window.open(
                        "https://www.notion.so/pawdly/4fa6d12dc21b4cd6aea49acbb10d16e1?pvs=4",
                        "_blank"
                      );
                    }}
                  >
                    <Text t-16-600-s8>사진 저장 방법 알아보기</Text>
                    <SvgIcon icon={SvgIconType.OpenInNew} size={20} />
                  </HFlex>
                )}
              </VFlex>
            )}

            <VFlex g-36>
              {data.notice && (
                <VFlex g-16 m-28-t>
                  <HFlex g-12>
                    <Text width={120} t-20-600>
                      전달 사항
                    </Text>
                  </HFlex>
                  <ServiceNoteSection title="전달 사항" content={data.notice} />
                </VFlex>
              )}

              <VFlex g-16>
                <HFlex g-12 m-40-t={!data.notice} m-0-t={data.notice}>
                  <Text t-20-600>미용 매너</Text>
                </HFlex>
                <MannerNoteSection
                  title="미용 매너"
                  content={data.beautyManner}
                  note={data.beautyMannerNotice}
                />
              </VFlex>

              <VFlex>
                <HFlex m-16-b g-12>
                  <Text t-20-600>컨디션 메모</Text>
                </HFlex>
                <VFlex bdr-16 bd-t2>
                  {data.notes.map((note: any, i: number) => (
                    <VFlex key={i} bd-t-t2={i > 0}>
                      <ConditionNoteSection
                        title={note.type.value}
                        content={note.value.type}
                        color={note.color.value}
                        note={note.note}
                      />
                    </VFlex>
                  ))}
                </VFlex>

                <HFlex
                  bd-h2
                  p-16-tb
                  m-28-t
                  bdr-16
                  c-c
                  onClick={() => {
                    trackEvent(amplitudeEvents.appDownloadBtnClick, {
                      location: "service_note_history_btn",
                    });
                    openAppLink("button_service_note");
                  }}
                >
                  <Text t-16-600-h2>
                    {data.pet.name}의 지난 알림장 내역 보기
                  </Text>
                </HFlex>
              </VFlex>
            </VFlex>
          </VFlex>
        )}

        {data && !data.writtenReview && (
          <HFlex
            a-c
            j-c
            g-8
            style={{
              position: "fixed",
              right: 24,
              bottom: 32,
              overflow: "hidden",
              width: btnWidth,
              height: 56,
              background: "rgba(0,0,0,0.8)",
              color: "#fff",
              borderRadius: 99,
              textAlign: "center",
              fontSize: 18,
              lineHeight: "56px",
              transition: "0.3s ease",
              zIndex: 1,
            }}
            onClick={() => {
              navigate(`/servicenotereview`, {
                state: { data, key },
              });
            }}
          >
            <VFlex
              style={{
                display: btnDisplay,
                transition: "0.3s ease",
              }}
            >
              감사 인사 전하기
            </VFlex>
            <SvgIcon size={24} icon={SvgIconType.Send} />
          </HFlex>
        )}
      </VFlex>
    </>
  );
}
