export const formatPhoneNumber = (previousValue?: string, currentValue?: string) => {
  if (!currentValue || !previousValue) {
    return currentValue;
  }
  currentValue = currentValue.replace(/[^0-9]/g, '');
  let formattedPhone = currentValue.slice(0, 3);
  if (
    previousValue.length > currentValue.length ? currentValue.length > 3 : currentValue.length >= 3
  ) {
    formattedPhone += `-${currentValue.slice(3, 7)}`;
  }
  if (
    previousValue.length > currentValue.length ? currentValue.length > 7 : currentValue.length >= 7
  ) {
    formattedPhone += `-${currentValue.slice(7, 11)}`;
  }
  return formattedPhone;
};
