export const days = ["일", "월", "화", "수", "목", "금", "토"];
export const daysEnglish = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];
