import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-hot-toast";

const client = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_AUTH_KEY,
  },
  baseURL: process.env.REACT_APP_DATA_API,
});

async function handleResponse(res: AxiosResponse) {
  if (res.data.httpStatus === "UNAUTHORIZED") {
    return;
  }

  if (res.data.apiCode !== "A01") {
    console.log(res.data, res.config.url);
    alert(
      `Error: ${res.data.message} (${res.data.apiCode})\nPath: ${res.config.url}`
    );
  }
  return res.data.data;
}

async function handleError(res: AxiosError) {
  if (res.config?.url !== "/ext/admin/bug-report?platform=USER_WEB") {
    if (window.location.href.includes("servicenotereview")) {
      return;
    }
    await api.post("/ext/admin/bug-report?platform=USER_WEB", {
      requestMethod: res.config?.method,
      apiPath: res.config?.url,
      errorType: res.response?.status,
      errorMessage: (res.response?.data as { message: string }).message,
    });
    toast.error("에러가 발생하였습니다. 다시 시도해 주세요.");
  }
}

async function axiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.get(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosPost<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.post(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosPut<T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.put(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosDelete<T = any>(url: string, config?: AxiosRequestConfig) {
  try {
    const res = await client.delete(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

const api = {
  get: axiosGet,
  post: axiosPost,
  delete: axiosDelete,
  put: axiosPut,
};

export default api;
