import _ from "lodash";
import { TextareaHTMLAttributes } from "react";
import {
  ColorProps,
  FontProps,
  LineProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type TextAreaProps = {
  classes?: string[];
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  FontProps &
  ColorProps &
  FontProps &
  LineProps;

const TextArea = ({ classes = [], ...props }: TextAreaProps) => {
  return (
    <textarea
      className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
      autoFocus={false}
      {...restOfProps(props)}
    />
  );
};

export default TextArea;
