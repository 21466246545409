import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import api from '../../common/api';
import { InvoiceData } from '../../common/types';
import useAmplitudeTrack, { amplitudeEvents } from '../../common/hooks/useAmplitudeTrack';
import { useAppLinkContext } from '../../modules/useAppLink';

import Timer from '../../components/Timer';
import InvoiceCardBottomSheet from '../InvoiceCardBottomSheet';
import InvoiceInstallmentBottomSheet from '../InvoiceInstallmentBottomSheet';
import InvoicePaidBottomSheet from '../InvoicePaidBottomSheet';

import VFlex from '../../layouts/VFlex';
import HFlex from '../../layouts/HFlex';
import Text from '../../layouts/Text';
import Image from '../../layouts/Image';

import _ from 'lodash';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import toast from 'react-hot-toast';

import SvgIcon, { SvgIconType } from '../../svg';

export const GapBlock = () => {
  return <VFlex width={'100%'} height={12} bc-t2></VFlex>;
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const calcPercnt = (percent: number, num: number) => {
  return (percent / 100) * num;
};

export default function Invoice() {
  const { trackEvent } = useAmplitudeTrack();
  const { openAppLink } = useAppLinkContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || searchParams.get('revNo');

  const [invoice, setInvoice] = useState<InvoiceData>();
  const [notiVisible, setNotiVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [serviceVisible, setServiceVisible] = useState(false);
  const [payType, setPayType] = useState(0);
  const [easyPayment, setEasyPayment] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardCode, setCardCode] = useState('');
  const [installment, setInstallment] = useState('');
  const [cardBottomSheetOpened, setCardBottomSheetOpened] = useState(false);
  const [installmentBottomSheetOpened, setInstallmentBottomSheetOpened] = useState(false);
  const [paidBottomSheetOpened, setPaidBottomSheetOpened] = useState(false);

  const easyPaymentList = [
    { id: 1, name: '카카오페이' },
    { id: 2, name: '네이버페이' },
    { id: 3, name: '토스' },
    { id: 4, name: '페이코' },
  ];

  useEffect(() => {
    (async () => {
      const invoice = await api.get(`/ext/invoice?key=${key}&platform=USER_WEB`);
      setInvoice(invoice);

      if (invoice?.invoices[0].expiredDate) {
        invoice?.invoices[0].expiredDate * 1000 - new Date().getTime() <= 0
          ? setPaidBottomSheetOpened(true)
          : setPaidBottomSheetOpened(false);
      }
    })();
  }, [key]);

  const handleInvoiceClick = async (key: string, cardCode: string, installment: string) => {
    const result = await api.get(
      `/ext/payment/url?key=${key}&pgType=secta9ine&cardCode=${cardCode}&installment=${installment}&platform=USER_WEB`
    );

    if (result) {
      window.open(result, '_blank');
    } else {
      console.log('err');
    }
  };

  if (_.isEmpty(invoice)) {
    return <></>;
  }

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: '100%',
          paddingBottom: 100,
        }}
      >
        <VFlex p-24-tb>
          <HFlex a-c j-b p-24-rl height={48}>
            <SvgIcon width={128} height={28} icon={SvgIconType.Logo} />
          </HFlex>

          <VFlex g-16 p-16-t p-24>
            <HFlex a-c j-b p-16-tb bd-b-t2>
              <HFlex g-16 a-c>
                <Image src={`/image/invoice.png`} />
                <Text t-18-600>
                  {invoice.group.title}에서
                  <br />
                  결제 요청이 도착했습니다.
                </Text>
              </HFlex>
              <VFlex
                bd-t3
                bdr-12
                p-8
                onClick={() => {
                  window.location.href = `tel://${invoice.group.contact}`;
                }}
              >
                <SvgIcon width={24} height={24} icon={SvgIconType.Call} />
              </VFlex>
            </HFlex>

            <VFlex g-4>
              <Text t-16-s4>
                {format(
                  new Date(invoice.reservation.reservationDate * 1000),
                  'yyyy.MM.dd(EEE) a h:mm',
                  {
                    locale: ko,
                  }
                )}
                &nbsp;예약건
              </Text>
              <HFlex t-18-600-h2 g-4>
                <Text>결제 제한까지 남은 시간</Text>
                <Timer time={invoice?.invoices[0].expiredDate * 1000 - new Date().getTime()} />
              </HFlex>
            </VFlex>
          </VFlex>

          {invoice.invoices.map((v) => v.comment)[0] !== '' && (
            <>
              <GapBlock />
              <VFlex g-24 p-24>
                <HFlex
                  j-b
                  onClick={() => {
                    setNotiVisible((prev) => !prev);
                  }}
                >
                  <Text t-18-600-s8>안내 사항</Text>
                  <HFlex
                    a-c
                    g-4
                    style={{
                      maxWidth: 196,
                    }}
                  >
                    {!notiVisible && (
                      <Text
                        t-16-s6
                        ovf-h
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          wordBreak: 'break-all',
                        }}
                      >
                        {invoice.invoices.map((v) => v.comment)}
                      </Text>
                    )}
                    <SvgIcon
                      size={24}
                      icon={notiVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown}
                    />
                  </HFlex>
                </HFlex>
                {notiVisible && (
                  <VFlex
                    p-12
                    bc-t1
                    bdr-12
                    style={{
                      maxHeight: 150,
                    }}
                  >
                    <Text t-16-s8 l-n>
                      {invoice.invoices.map((v) => v.comment)}
                    </Text>
                  </VFlex>
                )}
              </VFlex>
            </>
          )}

          <GapBlock />

          <VFlex g-24 p-24>
            <HFlex
              j-b
              onClick={() => {
                setInfoVisible((prev) => !prev);
              }}
            >
              <Text t-18-600-s8>고객 정보</Text>
              <HFlex
                a-c
                g-4
                style={{
                  maxWidth: 196,
                }}
              >
                {!infoVisible && (
                  <Text
                    t-16-s6
                    ovf-h
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {invoice.reservation.pet.name}
                  </Text>
                )}
                <SvgIcon size={24} icon={infoVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown} />
              </HFlex>
            </HFlex>
            {infoVisible && (
              <VFlex g-8>
                <HFlex j-b>
                  <Text t-16-s4>고객 정보</Text>
                  <Text t-16-s8>
                    {invoice.reservation.pet.name} &#40;{invoice.reservation.pet.species.name}&#41;
                  </Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>예약자 정보</Text>
                  <Text t-16-s8>
                    {invoice.reservation.reserver.nickname
                      ? invoice.reservation.reserver.nickname
                      : invoice.reservation.reserver.name}
                    &nbsp;&#40;{invoice.reservation.reserver.cellnumber}&#41;
                  </Text>
                </HFlex>
              </VFlex>
            )}
          </VFlex>

          <GapBlock />

          <VFlex p-24>
            <HFlex
              j-b
              onClick={() => {
                setServiceVisible((prev) => !prev);
              }}
            >
              <Text t-18-600-s8>서비스 내역</Text>
              <HFlex
                a-c
                g-4
                style={{
                  maxWidth: 196,
                }}
              >
                {!serviceVisible && (
                  <Text
                    t-16-s6
                    ovf-h
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {invoice.reservation.products[0].name}
                    {invoice.reservation.products.length > 1 &&
                      ` 외 ${invoice.reservation.products.length - 1}건`}
                  </Text>
                )}
                <SvgIcon
                  size={24}
                  icon={serviceVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown}
                />
              </HFlex>
            </HFlex>
            {serviceVisible && (
              <>
                <VFlex g-8 bd-b-t2 p-16-b p-24-t>
                  {invoice.reservation.products.map((product) => {
                    return (
                      <HFlex j-b key={product.id}>
                        <HFlex g-8>
                          <Text t-16-s4>{product.name}</Text>
                          <Text t-16-s2>&#215;{product.quantity}</Text>
                        </HFlex>
                        <Text t-16-s8>{numberWithCommas(product.price)}원</Text>
                      </HFlex>
                    );
                  })}
                </VFlex>
                <VFlex p-16-t>
                  <HFlex j-b>
                    <Text t-16-s4>서비스 금액</Text>
                    <Text t-16-s8>
                      {numberWithCommas(invoice.reservation.serviceTotalAmount)}원
                    </Text>
                  </HFlex>
                </VFlex>
              </>
            )}
          </VFlex>

          <GapBlock />

          <VFlex p-24>
            <Text t-18-600-s8 p-24-b>
              적립금/정액권
            </Text>
            <VFlex l-n bd-b-t2 p-16-b t-14-s4>
              <HFlex g-4>
                <Text>&#183;</Text>
                <Text l-n>
                  개인정보보호방침으로 인해 모바일 웹에서 해당 내용을 확인할 수 없습니다. 앱으로
                  이용 부탁드립니다.
                </Text>
              </HFlex>
            </VFlex>
            <HFlex
              g-4
              a-c
              j-c
              p-16-t
              onClick={() => {
                trackEvent(amplitudeEvents.appDownloadBtnClick, {
                  location: 'invoice',
                });
                openAppLink('invoice_to_check_app');
              }}
            >
              <Text t-16-600-pt>앱에서 확인하기</Text>
              <SvgIcon size={24} icon={SvgIconType.AppDown} />
            </HFlex>
          </VFlex>

          <GapBlock />

          <VFlex p-24>
            <Text t-18-600-s8 p-24-b>
              결제 수단
            </Text>
            <VFlex g-16>
              {/* <HFlex g-24>
                <HFlex
                  g-16
                  a-c
                  t-16-s8
                  onClick={() => {
                    setPayType(0);
                    setEasyPayment('');
                  }}
                >
                  <SvgIcon
                    size={20}
                    icon={payType === 0 ? SvgIconType.RadioOn : SvgIconType.RadioOff}
                  />
                  신용카드
                </HFlex>
                <HFlex
                  g-16
                  a-c
                  t-16-s8
                  onClick={() => {
                    setPayType(1);
                    setCardName('');
                    setInstallment('');
                  }}
                >
                  <SvgIcon
                    size={20}
                    icon={payType === 1 ? SvgIconType.RadioOn : SvgIconType.RadioOff}
                  />
                  간편결제
                </HFlex>
              </HFlex> */}

              {
                payType === 0 ? (
                  <VFlex g-10>
                    <HFlex
                      a-c
                      j-b
                      p-16
                      bd-t4
                      bdr-16
                      onClick={() => {
                        setCardBottomSheetOpened(true);
                      }}
                    >
                      <Text>{cardName ? cardName : '카드 선택'}</Text>
                      <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
                    </HFlex>
                    <HFlex
                      a-c
                      j-b
                      p-16
                      bd-t4
                      bdr-16
                      bc-t1={!cardName}
                      t-16-g4={!cardName}
                      onClick={() => {
                        if (!cardName) {
                          return;
                        } else {
                          setInstallmentBottomSheetOpened(true);
                        }
                      }}
                    >
                      <Text>{installment ? installment : '일시불'}</Text>
                      <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
                    </HFlex>
                  </VFlex>
                ) : null
                // <VFlex
                //   g-8
                //   j-b
                //   style={{
                //     flexWrap: 'wrap',
                //     flexDirection: 'unset',
                //   }}
                // >
                //   {easyPaymentList.map((easy) => {
                //     return (
                //       <VFlex
                //         key={easy.id}
                //         a-c
                //         p-16
                //         bdr-8
                //         width={'calc(100%/2 - 8px)'}
                //         bd-t3={easyPayment !== easy.name}
                //         bd-gr5={easyPayment === easy.name}
                //         t-16-s8={easyPayment !== easy.name}
                //         t-16-gr5={easyPayment === easy.name}
                //         onClick={() => {
                //           setEasyPayment(easy.name);
                //         }}
                //       >
                //         {easy.name}
                //       </VFlex>
                //     );
                //   })}
                // </VFlex>
              }
            </VFlex>
          </VFlex>

          <GapBlock />

          <VFlex p-24>
            <Text t-18-600-s8 p-24-b>
              결제 금액
            </Text>
            <VFlex g-8 bd-b-t2 p-16-b>
              <HFlex j-b>
                <Text t-16-s4>서비스 합계</Text>
                <Text t-16-s8>
                  {invoice.invoices.map((v) => {
                    return numberWithCommas(v.totalAmount);
                  })}
                  원
                </Text>
              </HFlex>
              <HFlex j-b>
                <Text t-16-s4>적립금 사용</Text>
                <Text t-16-s8>0원</Text>
              </HFlex>
              <HFlex j-b>
                <Text t-16-s4>가격 할인</Text>
                <HFlex t-16-s8>
                  <Text>
                    {invoice.invoices.map((v) => {
                      return v.discountRate === 0
                        ? invoice.invoices.map((v) => {
                            return v.discountAmount;
                          })[0] <= 0
                          ? numberWithCommas(v.discountAmount)
                          : `-${numberWithCommas(v.discountAmount)}`
                        : invoice.invoices.map((v) => {
                            return `-${numberWithCommas(
                              calcPercnt(v.totalAmount, v.discountRate)
                            )}`;
                          });
                    })}
                    원
                  </Text>
                  {invoice.invoices.map((v, i) => {
                    return v.discountRate <= 0 ? null : (
                      <Text key={i}>&#40;{v.discountRate}%&#41;</Text>
                    );
                  })}
                </HFlex>
              </HFlex>
              {invoice.invoices.map((v) => {
                return v.extraAmount;
              })[0] > 0 && (
                <HFlex j-b>
                  <Text t-16-s4>가격 추가</Text>
                  <Text t-16-s8>
                    +
                    {invoice.invoices.map((v) => {
                      return numberWithCommas(v.extraAmount as 0);
                    })}
                    원
                  </Text>
                </HFlex>
              )}
            </VFlex>
            <VFlex p-16-t g-8>
              <HFlex j-b>
                <Text t-18-600-s8>최종 결제 금액</Text>
                <Text t-18-600>
                  <Text t-18-600-pt>
                    {invoice.invoices.map((v) => {
                      return numberWithCommas(v.actualAmount);
                    })}
                  </Text>
                  &nbsp;원
                </Text>
              </HFlex>
              {!!invoice.group.mileagePolicy && (
                <HFlex j-b>
                  <Text t-16-s4>
                    예상 적립금&#40;
                    {
                      invoice.group.mileagePolicy.filter(
                        (mileage) => mileage.type === 'MILEAGE_CARD'
                      )[0].value
                    }
                    %&#41;
                  </Text>
                  <Text t-16-s8>
                    {numberWithCommas(
                      calcPercnt(
                        invoice.invoices.map((v) => v.actualAmount)[0],
                        Number(
                          invoice.group.mileagePolicy.filter((v) => v.type === 'MILEAGE_CARD')[0]
                            .value
                        )
                      )
                    )}
                    원
                  </Text>
                </HFlex>
              )}
            </VFlex>
          </VFlex>

          <GapBlock />

          <VFlex g-16 p-24>
            <VFlex g-8 bd-b-t2 p-16-b>
              <HFlex j-b>
                <Text t-14-s6>개인정보 수집이용 및 제 3자 제공 동의</Text>
                <Text
                  t-14-s4
                  style={{ textDecorationLine: 'underline' }}
                  onClick={() => {
                    window.open(
                      'https://pawdly.notion.site/75b65ca9b6a94a488f41fb2dd02ba1a3',
                      '_blank'
                    );
                  }}
                >
                  보기
                </Text>
              </HFlex>
              <HFlex j-b>
                <Text t-14-s6>결제대행 서비스 이용약관 동의</Text>
                <Text
                  t-14-s4
                  style={{ textDecorationLine: 'underline' }}
                  onClick={() => {
                    // TODO
                    console.log('동의 링크');
                  }}
                >
                  보기
                </Text>
              </HFlex>
            </VFlex>
            <Text t-16-600-s8>위 내용을 확인하였으며 결제에 동의합니다.</Text>
          </VFlex>
        </VFlex>

        <InvoiceCardBottomSheet
          open={cardBottomSheetOpened}
          onDismiss={() => setCardBottomSheetOpened(false)}
          onComplete={(card, code) => {
            setCardBottomSheetOpened(false);
            setCardName(card);
            setCardCode(code);
          }}
          cardList={invoice.cards}
          cardName={cardName}
        />

        <InvoiceInstallmentBottomSheet
          open={installmentBottomSheetOpened}
          onDismiss={() => setInstallmentBottomSheetOpened(false)}
          onComplete={(value) => {
            setInstallmentBottomSheetOpened(false);
            setInstallment(value);
          }}
          installment={installment}
        />
      </VFlex>

      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          onClick={async () => {
            // 결제 수단이 신용카드 일때
            if (payType === 0) {
              // 결제 기한 만료 시
              if (invoice?.invoices[0].expiredDate * 1000 - new Date().getTime() < 0) {
                return;
              }

              // const res = await api.post(
              //   `/rest/payment/invoice/${invoice.invoices.map((v) => v.id)[0]}?platform=USER_WEB`,
              //   {
              //     id: invoice.invoices.map((v) => v.id)[0],
              //     groupMileage: 0,
              //     status: invoice.invoices.map((v) => v.status)[0],
              //     groupId: invoice.invoices.map((v) => v.groupId)[0],
              //     objectId: invoice.invoices.map((v) => v.objectId)[0],
              //     objectType: invoice.invoices.map((v) => v.objectType)[0],
              //     totalAmount: invoice.invoices.map((v) => v.totalAmount)[0],
              //     actualAmount: invoice.invoices.map((v) => v.actualAmount)[0],
              //   }
              // );

              // navigate(`/invoicecomplete?key=${key}`, {
              //   state: {
              //     title: invoice.group.title,
              //     cardName: cardName,
              //     installment: installment,
              //     invoice,
              //     res,
              //   },
              // });

              const resInstallment = installment.includes('개월')
                ? installment.split('개월')[0]
                : installment === '일시불'
                ? '0'
                : installment;

              if (!cardName) {
                return toast.error('카드를 선택해주세요.');
              }
              handleInvoiceClick(invoice.invoices[0].issueNumber, cardCode, resInstallment);
            } else {
              // 걀제 수단이 간편결제 일때
              if (!easyPayment) {
                return;
              }

              navigate(`/invoicecomplete?key=${key}`, {
                state: {
                  title: invoice.group.title,
                  easyPayment: easyPayment,
                  invoice,
                },
              });
            }
          }}
        >
          {invoice.invoices.map((v) => {
            return numberWithCommas(v.actualAmount);
          })}
          원 결제하기
        </VFlex>
      </VFlex>

      <InvoicePaidBottomSheet
        open={paidBottomSheetOpened}
        onDismiss={() => setPaidBottomSheetOpened(false)}
        onComplete={() => {
          setPaidBottomSheetOpened(false);
        }}
      />
    </VFlex>
  );
}
