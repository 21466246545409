import { BottomSheet } from 'react-spring-bottom-sheet';

import HFlex from '../layouts/HFlex';
import VFlex from '../layouts/VFlex';
import Text from '../layouts/Text';

import 'react-spring-bottom-sheet/dist/style.css';

function InvoiceCardBottomSheet({
  open,
  onDismiss,
  onComplete,
  cardList,
  cardName,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (card: string, code: string) => void;
  cardList: [
    {
      name: string;
      code: string;
    }
  ];
  cardName: string;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          카드사를 선택해주세요
        </Text>
      </HFlex>
      <VFlex
        p-16-t
        p-24-rl
        p-32-b
        g-8
        style={{
          flexWrap: 'wrap',
          flexDirection: 'unset',
        }}
      >
        {cardList.map((card) => {
          return (
            <VFlex
              key={card.code}
              a-c
              p-16
              bdr-8
              width={'calc(100%/2 - 4px)'}
              bd-t3={cardName !== card.name}
              bd-gr5={cardName === card.name}
              t-16-s8={cardName !== card.name}
              t-16-gr5={cardName === card.name}
              onClick={() => {
                onComplete(card.name, card.code);
              }}
            >
              {card.name}
            </VFlex>
          );
        })}
      </VFlex>
    </BottomSheet>
  );
}

export default InvoiceCardBottomSheet;
