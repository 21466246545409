import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";

import { useAppLinkContext } from "../modules/useAppLink";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";

const APP_DOWNLOAD_TEXTS = [
  `언제 어디서나\n간편한 서비스 예약`,
  `가족, 친구, 연인과\n앱에서 공동양육하기`,
  `파트너가 보낸 알림장\n앱에서 관리하기`,
  `반려동물 건강상태\n간편하게 전달하기`,
];

function ReservationDoneBottomSheet({
  open,
  onDismiss,
}: {
  open: boolean;
  onDismiss: () => void;
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const { openAppLink } = useAppLinkContext();
  const { trackEvent } = useAmplitudeTrack();

  return (
    <BottomSheet
      className="bottomsheet no-handle"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={() => {
        trackEvent(amplitudeEvents.appDownloadBSDissmiss);
        onDismiss();
      }}
    >
      <Image
        src="/image/app_download_bottom_sheet/close.svg"
        size={24}
        style={{
          position: "absolute",
          zIndex: 2,
          inset: "20px 24px auto auto",
        }}
        onClick={() => {
          trackEvent(amplitudeEvents.appDownloadBSDissmiss);
          onDismiss();
        }}
      />
      <Swiper
        style={{
          width: "100%",
          height: "240px",
          backgroundColor: "#EEE9E3",
        }}
        slidesPerView={1}
        threshold={10}
        centeredSlides={true}
        onActiveIndexChange={(swiper) => setSlideIndex(swiper.activeIndex)}
      >
        {["1.png", "2.png", "3.png", "4.png"].map((c, i: number) => (
          <SwiperSlide key={i}>
            <VFlex
              bc-cc
              style={{
                height: "100%",
                backgroundImage: `url('/image/app_download_bottom_sheet/${c}')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <VFlex p-24-rl p-28-t p-40-b>
        <HFlex c-c g-8 m-28-b>
          {[1, 2, 3, 4].map((_, i) => {
            return (
              <VFlex
                key={i}
                bc-t3={i !== slideIndex}
                bc-pt={i === slideIndex}
                style={{ width: 8, height: 8, borderRadius: "100%" }}
              />
            );
          })}
        </HFlex>

        <Text
          t-28-700-s8
          m-32-b
          style={{
            whiteSpace: "pre-wrap",
            textAlign: "center",
            lineHeight: "36px",
          }}
        >
          {APP_DOWNLOAD_TEXTS[slideIndex]}
        </Text>
        <VFlex
          height={64}
          c-c
          bdr-16
          t-18-600-ww
          bc-pt
          onClick={() => {
            trackEvent(amplitudeEvents.appDownloadBtnClick, {
              location: "app_download_bottom_sheet",
            });
            openAppLink("bottom_sheet");
          }}
        >
          앱 다운로드
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default ReservationDoneBottomSheet;
