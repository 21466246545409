import * as amplitude from "@amplitude/analytics-browser";

export default function useAmplitudeTrack() {
  const identifyObj = new amplitude.Identify();

  const setIdentifyObj = (key: string, value: any) => {
    identifyObj.set(key, value);
    amplitude.identify(identifyObj);
  };

  const trackEvent = (event: string, properties?: Record<string, any>) => {
    amplitude.track(event, { ...properties, platform: "PUW" });
  };

  return { trackEvent, setIdentifyObj };
}

export const amplitudeEvents = {
  appDownloadBSDissmiss: "user_platform_app_download_bs_dismiss",
  appDownloadBtnClick: "user_platform_app_download_btn_click",
  reservationLand: "user_platform_reservation_land",
  reservationVisitConfirm: "user_platform_reservation_visit_confirm",
  inquiryLand: "user_platform_inquiry_land",
  inquiryComplete: "user_platform_inquiry_complete",
  serviceNoteLand: "user_platform_service_note_land",
  serviceNoteShare: "user_platform_service_note_share",
  serviceNoteImgDownloadGuideClick:
    "user_platform_service_note_img_download_guide_click",
  questionnaireLand: "user_platform_questionnaire_land",
  questionnaireFillIn: "user_platform_questionnaire_fill_in",
  questionnaireComplete: "user_platform_questionnaire_complete",
  serviceReviewLand: "user_platform_service_review_land",
  serviceReviewComplete: "user_platform_service_review_complete",
};
