import { BottomSheet } from 'react-spring-bottom-sheet';

import HFlex from '../layouts/HFlex';
import VFlex from '../layouts/VFlex';
import Text from '../layouts/Text';

import 'react-spring-bottom-sheet/dist/style.css';

function InvoiceInstallmentBottomSheet({
  open,
  onDismiss,
  onComplete,
  installment,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (card: string) => void;
  installment?: string;
}) {
  const installmentList = [
    { id: 1, name: '일시불' },
    { id: 2, name: '2개월 (무이자)' },
    { id: 3, name: '3개월 (무이자)' },
    { id: 4, name: '4개월' },
    { id: 5, name: '5개월' },
    { id: 6, name: '6개월' },
    { id: 7, name: '7개월' },
    { id: 8, name: '8개월' },
    { id: 9, name: '9개월' },
    { id: 10, name: '10개월' },
    { id: 11, name: '11개월' },
    { id: 12, name: '12개월' },
  ];

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          할부 여부를 선택해주세요
        </Text>
      </HFlex>
      <VFlex
        p-16-t
        p-24-rl
        p-32-b
        g-8
        style={{
          flexWrap: 'wrap',
          flexDirection: 'unset',
        }}
      >
        {installmentList.map((value) => {
          return (
            <VFlex
              key={value.id}
              a-c
              p-16
              bdr-8
              width={'calc(100%/2 - 4px)'}
              bd-t3={installment !== value.name}
              bd-gr5={installment === value.name}
              t-16-s8={installment !== value.name}
              t-16-gr5={installment === value.name}
              onClick={() => {
                onComplete(value.name);
              }}
            >
              {value.name}
            </VFlex>
          );
        })}
      </VFlex>
    </BottomSheet>
  );
}

export default InvoiceInstallmentBottomSheet;
