import { BottomSheet } from 'react-spring-bottom-sheet';

import HFlex from '../layouts/HFlex';
import VFlex from '../layouts/VFlex';
import Text from '../layouts/Text';

import 'react-spring-bottom-sheet/dist/style.css';

import SvgIcon, { SvgIconType } from '../svg';

function InvoicePaidBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: () => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600-s8>
          결제 {`기한이 만료`}되었습니다
          {/* 요청이 취소 */}
        </Text>
        <SvgIcon size={24} icon={SvgIconType.Close} onClick={() => onDismiss()} />
      </HFlex>
      <VFlex g-24 p-24-rl p-40-b>
        <Text f-1 t-16-s4 m-8-t>
          재요청을 원하실 경우 업체에게 문의해주세요
        </Text>
        <VFlex a-st height={64}>
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            onClick={() => {
              onComplete();
            }}
          >
            확인
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default InvoicePaidBottomSheet;
