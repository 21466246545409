import { useNavigate } from 'react-router-dom';

import VFlex from '../../layouts/VFlex';
import HFlex from '../../layouts/HFlex';
import Text from '../../layouts/Text';

import _ from 'lodash';

import SvgIcon, { SvgIconType } from '../../svg';

export default function InvoiceCancel() {
  const navigate = useNavigate();

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: '100%',
          paddingBottom: 100,
        }}
      >
        <VFlex p-24>
          <HFlex
            a-c
            height={56}
            style={{
              justifyContent: 'end',
            }}
          >
            <SvgIcon size={24} icon={SvgIconType.Close} />
          </HFlex>

          <VFlex a-c g-16 p-32-tb bd-b-t4>
            <SvgIcon size={72} icon={SvgIconType.InvoiceCancel} />
            <VFlex a-c g-8>
              <Text t-24-600-s8>결제 실패</Text>
              <VFlex a-c>
                <Text t-16-s6>카드 결제가 완료되지 못했습니다.</Text>
                <Text t-16-s6>다시 시도해주세요.</Text>
              </VFlex>
            </VFlex>
          </VFlex>

          <VFlex a-c p-16-tb t-16-s4>
            [신용카드] 기타오류(통장 잔액부족)
          </VFlex>

          <VFlex
            a-c
            t-16-s4
            style={{
              marginTop: 350,
            }}
            onClick={() => {
              window.open('https://pawdly.channel.io/lounge', '_blank');
            }}
          >
            결제 진행에 문제가 있나요?
          </VFlex>
        </VFlex>
      </VFlex>

      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex f-1 c-c bdr-16 t-18-ww bc-pt onClick={() => navigate(-1)}>
          결제 정보 화면으로 이동
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
