import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
    site: "datadoghq.com",
    service: "puw",
    env: "production",
    version: "1.1.8",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
