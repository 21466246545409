import { useEffect, useState } from 'react';
import Text from '../layouts/Text';

export default function Timer({ time }: { time: number }) {
  const TIMES = time;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(TIMES);

  const houres = String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(
    2,
    '0'
  );
  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      clearInterval(timer);
      setTimeLeft(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <Text>
      {houres}:{minutes}:{second}
    </Text>
  );
}
