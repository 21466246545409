import { Suspense, useEffect, useState } from "react";
import { useRoutes, useSearchParams } from "react-router-dom";
import routes from "./modules/router";
import { Toaster } from "react-hot-toast";
import * as amplitude from "@amplitude/analytics-browser";

import { MOBILE, TABLET, PC } from "./components/MediaQuery";

import VFlex from "./layouts/VFlex";
import AppDownloadBottomSheet from "./views/AppDownloadBottomSheet";

import "./css/App.scss";
import { AppLinkContextProvider } from "./modules/useAppLink";
import useSessionStorage from "./modules/useSessionStorage";

function App() {
  const amplitudeApikey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  amplitude.init(amplitudeApikey as string, {
    defaultTracking: false,
  });

  const [appDownloadBottomSheetOpened, setAppDownloadBottomSheetOpened] =
    useState(true);

  const [, setDevice] = useSessionStorage("DEVICE");

  let [searchParams] = useSearchParams();

  const routing = useRoutes(routes);

  useEffect(() => {
    let temp = window.navigator.userAgent;
    if (temp.match("APP_PARTNERS_ANDROID")) {
      setDevice("android");
    } else if (temp.match("APP_PARTNERS_IOS")) {
      setDevice("ios");
    } else {
      setDevice("");
    }
  }, [searchParams, setDevice]);
  return (
    <AppLinkContextProvider>
      <Suspense fallback="Loading...">
        <MOBILE>
          <VFlex a-c classes={["mobile_container"]} style={{ width: "100%" }}>
            {routing}
          </VFlex>
        </MOBILE>
        <TABLET>
          <VFlex a-c classes={["tablet_container"]} style={{ width: "100%" }}>
            {routing}
          </VFlex>
        </TABLET>
        <PC>
          <VFlex a-c classes={["pc_container"]} style={{ width: "100%" }}>
            {routing}
          </VFlex>
        </PC>
      </Suspense>

      <Toaster
        containerStyle={{ zIndex: 100000000002 }}
        toastOptions={{
          success: {
            position: "top-center",
            className: "p-24-rl",
            style: {
              height: 64,
              borderRadius: 24,
              color: "#ffff",
              backgroundColor: "#383B3A",
            },
            iconTheme: {
              primary: "#27BD63",
              secondary: "#ffff",
            },
          },
          error: {
            position: "top-center",
            className: "p-24-rl",
            style: {
              height: 64,
              borderRadius: 24,
              color: "#ffff",
              backgroundColor: "#383B3A",
            },
            iconTheme: {
              primary: "#B62C00",
              secondary: "#383B3A",
            },
          },
        }}
      />

      <AppDownloadBottomSheet
        open={appDownloadBottomSheetOpened}
        onDismiss={() => setAppDownloadBottomSheetOpened(false)}
      />
    </AppLinkContextProvider>
  );
}

export default App;
