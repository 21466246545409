import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import VFlex from '../../layouts/VFlex';
import HFlex from '../../layouts/HFlex';
import Text from '../../layouts/Text';

import _ from 'lodash';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

import SvgIcon, { SvgIconType } from '../../svg';
import { numberWithCommas } from './Invoice';

export default function InvoiceComplete() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key') || searchParams.get('revNo');
  const { state } = useLocation();
  const { title, cardName, installment, easyPayment, invoice, res } = state ?? {};

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: '100%',
          paddingBottom: 100,
        }}
      >
        <VFlex p-24>
          <HFlex
            a-c
            height={56}
            style={{
              justifyContent: 'end',
            }}
            onClick={() => {
              // TODO
              // window.location.href = 'kakaotalk://inappbrowser/close';
              // <a onclick="window.close(); location.href = 'kakaotalk://inappbrowser/close'; return false;">창 닫기</a>
            }}
          >
            <SvgIcon size={24} icon={SvgIconType.Close} />
          </HFlex>

          <VFlex a-c g-16 p-32-tb bd-b-t4>
            <SvgIcon size={72} icon={SvgIconType.CompleteCheck} />
            <VFlex a-c g-8>
              <Text t-24-600-s8>결제 완료</Text>
              <Text t-16-s6>결제가 완료되었습니다.</Text>
            </VFlex>
          </VFlex>

          <VFlex g-8 bd-b-t2 p-16-tb>
            <HFlex j-b>
              <Text t-16-s4>업체명</Text>
              <Text t-16-s8>{title}</Text>
            </HFlex>
            <HFlex j-b>
              <Text t-16-s4>결제 금액</Text>
              <Text t-18-600>
                <Text t-18-600-pt>
                  {invoice.invoices.map((v: any) => numberWithCommas(v.actualAmount))}
                </Text>
                &nbsp;원
              </Text>
            </HFlex>
          </VFlex>

          <VFlex g-8 p-16-tb>
            <HFlex j-b>
              <Text t-16-s4>결제 일시</Text>
              <Text t-16-s8>
                {format(new Date(res.paidTime * 1000), 'yyyy.MM.dd HH:MM', {
                  locale: ko,
                })}
              </Text>
            </HFlex>
            <HFlex j-b>
              <Text t-16-s4>결제 카드</Text>
              {/* <Text t-16-s8>{cardName ? cardName : easyPayment} ****1234 개인</Text> */}
              <Text t-16-s8>
                {res.cardCode.name} {res.cardNumber}
              </Text>
            </HFlex>
          </VFlex>

          <VFlex
            bc-t1
            p-16-tb
            p-24-rl
            bdr-16
            g-12
            style={{
              marginTop: 162,
            }}
          >
            <HFlex a-c g-8>
              <SvgIcon size={24} icon={SvgIconType.CompleteAlert} />
              <Text t-18-600-s8>확인해주세요!</Text>
            </HFlex>
            <VFlex t-16-s4>
              <Text>&#183; 결제 취소를 원할 경우 업체에 문의해주세요.</Text>
              <Text>&#183; 결제 취소 시 적립금이 회수될 수 있습니다.</Text>
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>

      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          onClick={() => {
            navigate(`/invoicedetail?key=${key}`, {
              state: {
                res,
                invoice,
                cardName: cardName,
                installment: installment,
                easyPayment: easyPayment,
              },
            });
          }}
        >
          결제 내역 확인하기
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
